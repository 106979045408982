<template>
  <div class="wel">welcome</div>
</template>

<style lang="scss" scoped>
.wel {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 200px;
  font-weight: bold;
}
</style>